var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"wizard wizard-2",attrs:{"id":"kt_wizard_v2","data-wizard-state":"step-first","data-wizard-clickable":"true"}},[_c('div',{staticClass:"wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10"},[_c('div',{staticClass:"wizard-steps"},[_c('div',{staticClass:"wizard-step",attrs:{"data-wizard-type":"step","data-wizard-state":"current"}},[_c('img',{attrs:{"src":_vm.detail.image,"width":"200","alt":""}})])])]),_c('div',{staticClass:"wizard-body py-8 px-8 py-lg-20 px-lg-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-xxl-2 col-xxl-8"},[_c('form',{staticClass:"form",attrs:{"id":"kt_form"}},[_c('div',{staticClass:"pb-5",attrs:{"data-wizard-type":"step-content","data-wizard-state":"current"}},[_c('h5',{staticClass:"font-weight-bold text-dark"},[_vm._v("Detail Kategori")]),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-borderless"},[_c('tr',[_vm._m(0),_vm._m(1),_c('td',{staticClass:"pl-2"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.detail.name))])])]),_c('tr',[_vm._m(2),_vm._m(3),_c('td',{staticClass:"pl-2"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.detail.description))])])])])])]),_c('div',{staticClass:"d-flex justify-content-between border-top pt-10"},[_c('div',[_c('b-button',{staticClass:"ml-2",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.$router.push('/masters/categories')}}},[_vm._v(" Tutup ")]),(_vm.btnAccess)?_c('b-button',{staticClass:"ml-2",attrs:{"type":"button","variant":"success"},on:{"click":function($event){return _vm.$router.push(
                              '/masters/categories/edit/' + _vm.$route.params.id
                            )}}},[_vm._v(" Edit ")]):_vm._e()],1)])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pr-1",attrs:{"width":"130"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nama")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pr-1",attrs:{"width":"130"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Deskripsi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
}]

export { render, staticRenderFns }